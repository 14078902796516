import React, { useEffect, useState } from "react"

export const ExtraServicesTemplate = props => {
  const encounteredKeywords = new Set()
  const [widthSize, setWidthSize] = useState("")
  function handleResize() {
    setWidthSize(window.innerWidth)
  }
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <section className='text-white'>
      {props.data.map(info => {
        const question = info.question.split(" ")
        const answer = info.answer.split(" ")
        const detailTitle = info.detailTitle.split(" ")
        const detailDescription = info.detailDescription.split(" ")
        const style = { color: "#f890e7" }
        return (
          <>
            <div className=' py-[40px] w-[90%] mx-auto text-center'>
              <h3 className='text-[2.2rem] font-secondary font-bold mx-auto'>
                {question.map(word => {
                  const newKeyWords = info.keywords?.flatMap(phrase => phrase.split(" "))
                  if (newKeyWords.includes(word)) {
                    if (!encounteredKeywords.has(word)) {
                      encounteredKeywords.add(word)
                      return <span style={style}>{word} </span>
                    }
                    return word + " "
                  }
                  return word + " "
                })}
              </h3>
              <div className='flex flex-wrap mt-[10px] items-center gap-[20px]'>
                <p className='text-[1.2rem] font-subtitle mt-[20px] sm:basis-[50%] lg:text-[1.4rem] xl:basis-[40%] xl:mx-auto'>
                  {answer.map(word => {
                    const newKeyWords = info.keywords?.flatMap(phrase => phrase.split(" "))
                    if (newKeyWords.includes(word)) {
                      if (!encounteredKeywords.has(word)) {
                        encounteredKeywords.add(word)
                        return <span style={style}>{word} </span>
                      }
                      return word + " "
                    }
                    return word + " "
                  })}
                </p>
                <img className='w-[280px] mx-auto mt-[20px] sm:w-[320px] lg:w-[360px] xl:w-[420px] rounded-[10px]' src={info.img1} alt='' />
              </div>
            </div>
            <div className='py-[40px] w-[90%] mx-auto text-center'>
              <h3 className='text-[2.2rem] font-secondary font-bold mx-auto'>
                {detailTitle.map(word => {
                  if (info.keywords.includes(word)) {
                    return <span style={style}>{word} </span>
                  }
                  return word + " "
                })}
              </h3>
              <div className='flex flex-wrap mt-[10px] items-center gap-[20px]'>
                <img className='w-[280px] mx-auto mt-[20px] sm:w-[320px] lg:w-[360px] xl:w-[420px] rounded-[10px]' src={info.img2} alt='' />
                <p className='text-[1.2rem] font-subtitle mt-[20px] sm:basis-[50%] lg:text-[1.4rem] xl:basis-[40%] xl:mx-auto'>
                  {detailDescription.map(word => {
                    const newKeyWords = info.keywords?.flatMap(phrase => phrase.split(" "))
                    if (newKeyWords.includes(word)) {
                      return <span style={style}>{word} </span>
                    }
                    return word + " "
                  })}
                </p>
              </div>
            </div>
            <div className='py-[40px] w-[100%] mx-auto'>
              <div
                style={widthSize >= 640 && info.types.length <= 3 ? { justifyContent: "center" } : {}}
                className='flex overflow-x-auto w-[95%] mx-auto gap-[20px]'
              >
                {info.types?.map((data, index) => {
                  const details = data.detail[0].split(" ")
                  return (
                    <div
                      key={index}
                      className=' min-w-[280px] max-w-[280px] my-[20px] p-[10px] border border-[#0bd3d3] rounded-[10px] md:min-w-[320px] '
                    >
                      <h4 className='text-center text-[#f890e7] text-[1.8rem] font-secondar'>{data.type}</h4>
                      <p className='text-[1.2rem] text-center mt-[10px] font-subtitle' key={index}>
                        {details?.map(word => {
                          const redirect = data.redirectWords
                          if (redirect?.includes(word)) {
                            if (!encounteredKeywords.has(word)) {
                              encounteredKeywords.add(word)
                              return (
                                <>
                                  {data.redirectWords ? (
                                    <a
                                      style={{ color: "#0bd3d3" }}
                                      href={data.redirectExtension ? data.redirectExtension : data.redirectUrl}
                                      target={data.redirectExtension ? "_self" : "_blank"}
                                      rel='noreferrer'
                                    >
                                      {word}{" "}
                                    </a>
                                  ) : (
                                    <span style={style}>{word} </span>
                                  )}
                                </>
                              )
                            }
                          }
                          return word + " "
                        })}
                      </p>
                    </div>
                  )
                })}
              </div>
              <img className='w-[280px] md:w-[40%] mx-auto my-[20px] rounded-[10px]' src={info.img3} alt='' />
            </div>
          </>
        )
      })}
    </section>
  )
}
