export const BrandingData = [
  {
    keywords: ["ZenTech", "Branding", "story"],
    question: `What is Branding ?`,
    answer: `As easy as it may sound branding is not just a logo. In order to create a brand you need to have a story to back it up. Whether it is a story filled with emotions or a story filled with facts. Here at ZenTech Media Solutions we help take your business and turn it into more than just a product or service.`,
    detailTitle: `We are always looking for a new story to create.`,
    detailDescription:
      "There isn't a template for creating a story that can work for everyone. We work to create a unique experience for users in order to help send a message. With the amount of brands that exist we want to make sure that we create something that stands out from the crowd.",
    types: [
      {
        type: "Storytelling",
        detail: [
          `We want to drive engagement to your business by giving your brand a story that is authentic and filled with emotions. Making connections that users can relate to in order to have that trust and loyalty goes a long way.`,
        ],
      },
      {
        redirectWords: ["conversion", "rate"],
        redirectExtension: "/#/marketing/conversion-rate-optimization",
        type: "Research and Strategy",
        detail: [
          `At ZenTech we work to make your brand an extension of your product/service. By looking at your strengths and weaknesses we are able to create new opportunities. We look at your target audience and find ways to reach out to gain a better conversion rate.`,
        ],
      },
      {
        type: "Name and Tagline",
        detail: [
          `After creating a story and doing research we can begin giving it a name. The name should be catchy and clever not cliche. We want people to understand the story within a few words.`,
        ],
      },
      {
        redirectWords: ["Miami", "Lights", "Studio"],
        redirectUrl: "https://www.miamilightsstudio.co/",
        type: "Logo/Identity",
        detail: [
          `Our partners at Miami Lights Studio are great at creating a logo that encompasses a story, research, and the name. With the combination of it all, they are able to give more meaning to your logo making it more engaging and captivating.`,
        ],
      },
    ],
    img1: "https://images.pexels.com/photos/1448709/pexels-photo-1448709.jpeg?",
    img2: "https://images.pexels.com/photos/259756/pexels-photo-259756.jpeg?",
  },
]
export const WebDesignData = [
  {
    keywords: ["Design", "design", "story", "message"],
    question: "What makes a good Design ?",
    answer:
      "A design is made with the brand in mind. This makes for a cohesive feel. By making a web design an extension of your brand you are adding to the story and message to get users to interact with your website. Creating a great design is not only meant to look good but also guide the user to a goal.",
    detailTitle: "We are ready to create a design suited for your business.",
    detailDescription:
      "We make sure that we go over a few key details to help us come up with a way to make a design that catches the message of the product or service that you provide. Our team makes sure designs are captivating, simple, and effective.",
    types: [
      {
        type: "Visually Aesthetic ",
        detail: [
          `Creating a website that looks appealing is what will attract users but making sure that it is well put together so that users aren't overwhelmed is what will keep them engaged. We make sure to design an interface that adds excellent user experience to appeal to your target audience.`,
        ],
      },
      {
        redirectWords: ["conversions"],
        redirectExtension: "/#/marketing/conversion-rate-optimization",
        type: "Call To Actions",
        detail: [
          `Setting up the design involves making sure that not only does it attract users but it also converts sales. We strategically guide users to call to actions in order to get those conversions rolling.`,
        ],
      },
    ],
    img1: "https://images.pexels.com/photos/1779487/pexels-photo-1779487.jpeg?",
    img2: `https://images.pexels.com/photos/326514/pexels-photo-326514.jpeg?`,
    img3: "https://images.pexels.com/photos/11813187/pexels-photo-11813187.jpeg?",
  },
]
export const SocialMediaData = [
  {
    keywords: ["Social Media Management", "ZenTech", "research", "plan", "engaging"],
    question: "What does Social Media Management include?",
    answer:
      "In order to have a social media presence you may require to have engaging post that captivates the attention of users. You also need to stay on top of comments and direct messages. Here at ZenTech Media Solutions we will analyze the engagement to provide your social media with posts that lead to conversions.",
    detailTitle: "Allow us to help you manage your social media",
    detailDescription:
      "We believe that social media is about staying up to date and doing research to create posts that not only get likes and views but conversions. We will help create a plan to help target your audience and convert those likes into clients. ",
    types: [
      {
        type: "Management",
        detail: [
          `Here at ZenTech Media Solutions we can help you manage your social media by helping create captions, engaging with users, and analyzing/monotoring engagement. Our primary goal is to not only get the most likes and views but also convert that engagement into clients for your business.`,
        ],
      },
      {
        redirectWords: ["Miami", "Lights", "Studio"],
        redirectUrl: "https://www.miamilightsstudio.co/",
        type: "Content Creation",
        detail: [
          "ZenTech does not create content for your social media but our Parnters at Miami Lights Studio can definitely help. They offer content such as image and video posts as well as reels and stories for instagram and other social media platform.",
        ],
      },
    ],
    img1: "https://images.pexels.com/photos/1629212/pexels-photo-1629212.jpeg?",
    img2: "https://images.pexels.com/photos/2818118/pexels-photo-2818118.jpeg?",
    img3: "https://images.pexels.com/photos/147413/twitter-facebook-together-exchange-of-information-147413.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
]
export const LandingPageData = [
  {
    keywords: ["Landing Pages", "audience", "online presence", "efficiency"],
    question: "What is so important about Landing Pages?",
    answer:
      "There are several reasons to have a landing page but the main reasons are for getting more traffic to your ecommerce site, target a specific audience for a specific product or service, and to showcase the arrival of a new product or service. Our team is always ready to create a landing page and target your audience.",
    detailTitle: "We want to make sure that your online presence makes a difference.",
    detailDescription:
      "Here at ZenTech Media Solutions we make sure that landing pages are created with visual appeal and efficiency. By creating a landing page for your business we are creating opportunities to reach more users.",
    types: [
      {
        type: "Set Up",
        detail: [
          "We want to make sure that the landing page we create is meant to target a specific audience so that we are able to create more conversions from views.",
        ],
      },
      {
        redirectWords: ["creating"],
        redirectExtension: "/#/web-development/web-design",
        type: "Visual Appeal",
        detail: [
          "Creating a landing page is important to have a bigger reach in the evergrowing internet, however we also need to make sure that we are creating something that attracts users.",
        ],
      },
      {
        type: "CTA",
        detail: [
          "Call to actions are meant to be one of the most important elements of a landing page. Our goal is to have users click on the call to actions in order for them to get your product or service.",
        ],
      },
      {
        type: "User Experience",
        detail: [
          "We want to make sure that users visit the landing page and automatically get a feel for your business. Every click and scroll should be easy for the user to receive a message.",
        ],
      },
    ],
    img1: "https://images.pexels.com/photos/35550/ipad-tablet-technology-touch.jpg?",
    img2: "https://images.pexels.com/photos/669996/pexels-photo-669996.jpeg?",
    img3: "https://images.pexels.com/photos/163097/twitter-social-media-communication-internet-network-163097.jpeg?",
  },
]
export const SalesOptimizationData = [
  {
    keywords: ["Sales", ""],
    question: "I get so many visitors but no Sales, why?",
    answer: `Views and visitors can be a great thing but it is much more important to get conversions. There many ways to turn those viewers and visitors into potential clients so let our team help you achieve this.`,
    detailTitle: "We are enthusiastic to begin a journey!",
    detailDescription:
      "We are always ready to take on a project. Whether you are starting out or already have a following, we are ready to help you grow.",
    types: [
      {
        redirectWords: ["social", "media"],
        redirectExtension: "social-media-management",
        type: "Social Media",
        detail: [
          "At ZenTech we take care of your social media presence to help you get more than just views and likes. We want to turn those likes into profit by attracting the right audience.",
        ],
      },
      {
        redirectWords: ["online", "presence"],
        redirectExtension: "/#/web-development/landing-pages",
        type: "World Wide Web",
        detail: ["We can help you create a bigger presence online as well as turn online users into potential clients."],
      },
      {
        type: "Research and Analytics",
        detail: [
          `With an online presence you are able to reach clients that you once were unable to before. We look at ways to reach those potential clients to help get a higher conversion rate.`,
        ],
      },
    ],
    img1: "https://images.pexels.com/photos/669616/pexels-photo-669616.jpeg?",
    img2: "https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?",
    img3: "https://images.pexels.com/photos/251225/pexels-photo-251225.jpeg?",
  },
]
export const SEOData = [
  {
    keywords: [""],
    question: "I have a website but its not getting as much attention as I would like.",
    answer:
      "Creating a website and having it online is half the job. Search Engine Optimization is the other half, a good amount of time put into SEO is crucial to keep your website ranked higher up.",
    detailTitle: "We create every website and landing page with essential SEO.",
    detailDescription: `By creating everything with SEO in mind we are able to help browsers index and rank your website higher. We are ready to begin any project whether it's a landing page or a full website.`,
    types: [
      {
        type: "The Long Run",
        detail: [
          "As your long term growth partner, we want to see you get more than just views and traffic to your website. We aim to maximize your conversion for more sales.",
        ],
      },
      {
        redirectWords: ["create"],
        redirectExtension: "/#/web-development",
        type: "Analyze",
        detail: [
          "We look at the analytics of your current website or the website that we can create. We report directly to you to make sure that you are aware of the progress that your website makes.",
        ],
      },
    ],
    img1: "https://images.pexels.com/photos/270669/pexels-photo-270669.jpeg?",
    img2: "https://images.pexels.com/photos/2351844/pexels-photo-2351844.jpeg?",
    img3: "https://images.pexels.com/photos/3785932/pexels-photo-3785932.jpeg?",
  },
]
